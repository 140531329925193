import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PrimaryButton } from 'glints-aries/lib/@next/Button';
import { Popover } from 'glints-aries/lib/@next/Popover';
import { Typography } from 'glints-aries/lib/@next/Typography';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import { get, isUndefined } from 'lodash';
import moment from 'moment';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import { CompanyStatus } from 'src/common/enums/companies';
import { useCompanyLocation } from 'src/common/hooks/useCompanyLocation';
import { useGetRegularScreenSize } from 'src/common/hooks/useScreenChecking';
import { useCurrentHref } from 'src/common/hooks/useUrl';
import { MetadataRenderer } from 'src/components/MetaDescription/MetadataRenderer';
import { Company, companySizeMap } from 'src/global/models/Company';
import {
  getBasename,
  getCountryWithSGFallback,
} from 'src/modules/ClientConfig/Selectors';
import { getCompanyNormalisedLocationEnabled } from 'src/modules/Unleash/Selectors';

import * as S from '../../CompanyPage.sc';
import { CompanyTabTypes, getLocalizationTab, TabListItems } from './const';
import { getVerifiedArticalUrl } from './helper';
import * as Styled from './styles.sc';

const metadataTitleMessage = defineMessage({
  id: 'company-page.metadata.title',
  defaultMessage: '{companyName} Career Information {year} | Glints',
});

const metadataDescriptionMessage = defineMessage({
  id: 'company-page.metadata.description',
  defaultMessage:
    'Apply to job opportunities at {companyName}. Get the latest information about building career at {companyName}, review & company culture with Glints',
});
const getMonth = () => moment().format('MMMM');
const getMonthNumber = () => moment().format('M');

interface Props {
  company: Company;
  relevantJobsLength: number | undefined;
  sectionRefs: React.RefObject<HTMLElement>[] | null[];
}

export const TopFoldCompanySection: React.FC<
  React.PropsWithChildren<Props>
> = ({ company, sectionRefs, relevantJobsLength }) => {
  const intl = useIntl();
  const lang = intl.locale;
  const url = useCurrentHref();
  const companyLocation = useCompanyLocation(company);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const basename = useSelector(getBasename);
  const router = useRouter();
  const country = useSelector(getCountryWithSGFallback);

  const [verifiedPopoverActive, setVerifiedPopoverActive] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showPopover = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setVerifiedPopoverActive(true);
  };

  const hidePopover = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setVerifiedPopoverActive(false);
    }, 300);
  }, []);

  const cancelHidePopover = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const noParamsUrl = url.split('?')[0];
  const month = getMonth();
  const monthNumber = getMonthNumber();

  const isVIP = get(company, 'isVIP');
  const isCompanyVerified = get(company, 'status') === CompanyStatus.VERIFIED;
  const industryName = get(company, 'links.industry.name');
  const countryName = get(company, 'links.country.name');

  const currentScreen = useGetRegularScreenSize();
  const isDesktopScreen = currentScreen === 'desktop';

  const companyNormalisedLocationEnabled = useSelector(
    getCompanyNormalisedLocationEnabled
  );

  const title = intl.formatMessage(metadataTitleMessage, {
    companyName: company?.name,
    month,
    year: new Date().getFullYear(),
  });

  const description = intl.formatMessage(metadataDescriptionMessage, {
    companyName: company?.name,
    year: new Date().getFullYear(),
    monthNumber,
    month,
  });

  const meta = {
    title,
    description,
    canonical: noParamsUrl,
  };

  const pathname = basename
    ? router.asPath.replace(basename, '')
    : router.asPath;

  useEffect(() => {
    const onScroll = () => setIsScrolling(window.scrollY > 0);

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Styled.Main>
      <Styled.CollapsibleContainer isScrolling={isScrolling}>
        <MetadataRenderer {...meta} />
        <Head>
          <If condition={!isCompanyVerified}>
            <meta name="robots" content="noindex" />
          </If>
          <If condition={country === CountryCodes.ID}>
            <link
              rel="alternate"
              hrefLang="id-id"
              href={`https://glints.com/id${pathname}`}
            />
            <link
              rel="alternate"
              hrefLang="en-id"
              href={`https://glints.com/id/en${pathname}`}
            />
          </If>
        </Head>
        <Styled.Header>
          <If condition={isDesktopScreen}>
            <S.Logo
              logo={company?.logo}
              alt="Company Logo"
              sizes="160px"
              breakpoints={160}
              lazy={false}
            />
          </If>

          <S.Overview>
            <S.CompanyNameContainer>
              <S.NameWrapper>
                <If condition={isCompanyVerified}>
                  <S.VerifiedBadge>
                    <Choose>
                      <When condition={isVIP}>
                        <S.ShieldBadge src="images/icons/vip-badge.svg" />
                      </When>
                      <Otherwise>
                        <S.Icon
                          name="ri-shield-check"
                          style={{
                            fill: Blue.S95,
                          }}
                        />
                      </Otherwise>
                    </Choose>
                  </S.VerifiedBadge>
                </If>
                <Typography variant="headline5" as="h1">
                  {company?.name}
                </Typography>
              </S.NameWrapper>
              <If condition={!isDesktopScreen}>
                <S.Logo
                  logo={company?.logo}
                  alt="Company Logo"
                  sizes="64px"
                  breakpoints={64}
                  lazy={false}
                />
              </If>
            </S.CompanyNameContainer>

            <If condition={company.tagline}>
              <S.Tagline>{company.tagline}</S.Tagline>
            </If>

            <S.AttributeList isVip={isVIP}>
              <If condition={company?.CountryCode}>
                <S.AttributeItem>
                  <S.AttributeIcon name="ri-map-line" />
                  <Choose>
                    <When condition={companyNormalisedLocationEnabled}>
                      <S.AttributeValue>{companyLocation}</S.AttributeValue>
                    </When>
                    <Otherwise>
                      <S.AttributeValue>{countryName}</S.AttributeValue>
                    </Otherwise>
                  </Choose>
                </S.AttributeItem>
              </If>
              <If condition={company?.size}>
                <S.AttributeItem>
                  <S.AttributeIcon name="ri-group-line" />
                  <S.AttributeValue>
                    {intl.formatMessage(companySizeMap[company.size])}
                  </S.AttributeValue>
                </S.AttributeItem>
              </If>
              <If condition={industryName}>
                <S.AttributeItem>
                  <S.AttributeIcon name="ri-building-line" />
                  <S.AttributeValue>{industryName}</S.AttributeValue>
                </S.AttributeItem>
              </If>

              <If condition={isCompanyVerified && company?.reviewedAt}>
                <S.AttributeItem>
                  <S.AttributeIcon name="ri-shield-check-line" />
                  <S.AttributeValue>
                    <FormattedMessage
                      id="text-verified-date"
                      defaultMessage="Verified {dateTime}"
                      values={{
                        dateTime: company.reviewedAt
                          ? moment(company.reviewedAt).format('MMMM YYYY')
                          : '',
                      }}
                    />
                    <Popover
                      active={verifiedPopoverActive}
                      preferredPosition="above"
                      activator={
                        <S.VerifiedPopoverIcon
                          name="ri-information-line"
                          onMouseEnter={showPopover}
                          onMouseLeave={hidePopover}
                        />
                      }
                      onClose={() => setVerifiedPopoverActive(false)}
                    >
                      <Popover.Pane>
                        <S.PopoverContainer
                          onMouseEnter={cancelHidePopover}
                          onMouseLeave={hidePopover}
                        >
                          <S.PopoverTextContainer>
                            <Typography as="span" variant="subtitle1">
                              <FormattedMessage
                                id="title-text-verified-badge"
                                defaultMessage="Verified Badge"
                              />
                            </Typography>
                            <Typography variant="body1">
                              <FormattedMessage
                                id="body-text-verified-badge"
                                defaultMessage="Glints has verified this company’s legal documents"
                              />
                            </Typography>
                          </S.PopoverTextContainer>
                          <div>
                            <PrimaryButton
                              onClick={() =>
                                window.open(
                                  getVerifiedArticalUrl(lang),
                                  '_blank'
                                )
                              }
                            >
                              <FormattedMessage
                                id="text-learn-more"
                                defaultMessage="Learn More"
                              />
                            </PrimaryButton>
                          </div>
                        </S.PopoverContainer>
                      </Popover.Pane>
                    </Popover>
                  </S.AttributeValue>
                </S.AttributeItem>
              </If>
            </S.AttributeList>

            <If condition={isVIP}>
              <S.VipTextSection>
                <S.VipTextSectionBadgeImage src="images/icons/vip-badge.svg" />
                <S.VipTextRightSection>
                  <FormattedMessage
                    id="text-premium-employer"
                    defaultMessage="Premium Employer"
                    tagName={S.VipTextHeadline}
                  />
                  <FormattedMessage
                    id="text-verified-company-enhance-hiring-process"
                    defaultMessage="This verified company uses Premium features to enhance their hiring process"
                    tagName="p"
                  />
                </S.VipTextRightSection>
              </S.VipTextSection>
            </If>
          </S.Overview>
        </Styled.Header>
      </Styled.CollapsibleContainer>

      <Styled.TabsListContainer>
        <Styled.TabsSection>
          <Styled.TabText>
            <Styled.TabList>
              {!isUndefined(relevantJobsLength) && (
                <Tabs
                  sectionRefs={sectionRefs}
                  relevantJobsLength={relevantJobsLength}
                />
              )}
            </Styled.TabList>
          </Styled.TabText>
        </Styled.TabsSection>
      </Styled.TabsListContainer>
    </Styled.Main>
  );
};

const Tabs = ({
  sectionRefs,
  relevantJobsLength,
}: Pick<Props, 'sectionRefs'> & {
  relevantJobsLength: number;
}) => {
  const hasJobs = relevantJobsLength > 0;

  const [currentTab, setCurrentTab] = useState<CompanyTabTypes>(
    hasJobs ? TabListItems[0] : TabListItems[1]
  );

  const scrollToSection = (tab: CompanyTabTypes) => {
    const index = TabListItems.indexOf(tab);
    if (index !== -1 && sectionRefs[index]) {
      sectionRefs[index].current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const selectedTabs = hasJobs ? TabListItems : TabListItems.slice(1);

  const handleTabClick = (tab: CompanyTabTypes) => {
    setCurrentTab(tab);
    scrollToSection(tab);
  };

  return (
    <>
      {selectedTabs.map((tab, key) => {
        return (
          <Styled.TabListItem
            key={key}
            className={currentTab === tab ? 'active' : ''}
            onClick={() => handleTabClick(tab)}
          >
            <Typography variant="body1">
              {getLocalizationTab(tab, relevantJobsLength)}
            </Typography>
          </Styled.TabListItem>
        );
      })}
    </>
  );
};
